import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	INegocioUnidadeCleanDTO,
	INegocioUnidadeCustomDTO,
	INegocioUnidadeFullDTO,
} from '../models/happy-code-api.model';
import { INegocioUnidadeDTO } from './../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useNegocioUnidadeService = () => {
	const resourceURL = 'negocio-unidade';

	const patchCustom = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<INegocioUnidadeDTO>> =>
		http.patch<INegocioUnidadeDTO>(resourceURL + `/custom/${id}`, dto);

	const findCustomDTO = (unidadeId: number): Promise<AxiosResponse<INegocioUnidadeCustomDTO[]>> =>
		http.get<INegocioUnidadeCustomDTO[]>(resourceURL +`/custom-dto`, {
			params: { unidadeId }, 
		});

	return {
		...baseFind<INegocioUnidadeCleanDTO, INegocioUnidadeDTO, INegocioUnidadeFullDTO>(resourceURL),
		...baseFindById<INegocioUnidadeCleanDTO, INegocioUnidadeDTO, INegocioUnidadeFullDTO>(resourceURL),
		...baseFindList<INegocioUnidadeCleanDTO, INegocioUnidadeDTO, INegocioUnidadeFullDTO>(resourceURL),
		patchCustom,
		findCustomDTO,
	};
};
