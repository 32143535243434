import { useCallback, useState } from "react";
import { useRelatorioAulaService } from "../../../../../services/aca-relatorio-aula.service";
import { IRelatorioAulaFullDTO } from "../../../../../models/happy-code-api.model";
import { useGlobalContext } from "../../../../../context/GlobalContext";
import { Predicate } from "../../../../../models/predicate.model";

export interface UseDadosExportExcelProps {
    usuario: string;
    titulo: string;
    descricao: string;
    anoTurma: string;
    produtoRelatorioAula: string;
    datacriacao: Date;
}

export function useDadosExcel(){
    const relatorioAulaService = useRelatorioAulaService();
    const [dataExcel, setDataExcel] = useState<IRelatorioAulaFullDTO[]>();
    const {setIsGlobalLoading} = useGlobalContext();
  
   const fetchDataForExcel = useCallback(async () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);
    setIsGlobalLoading(true);
    const response = await relatorioAulaService.findFullList(predicate);
    setDataExcel(response.data);
    setIsGlobalLoading(false);
  }, [setIsGlobalLoading, relatorioAulaService]);
 
  return {
    dataExcel,
    fetchDataForExcel
  }
}