import { Fragment } from 'react';
import { WEEK_DAYS_ACRONYMS } from '../../../constants/week-days-acronyms.constant';
import {
	IDiaSemana,
	IMatriculaDTO,
	ITurmaGradeDTO,
	ITurmaGradeSemanalDTO,
} from '../../../models/happy-code-api.model';
import { MiniaturaTurma } from '../../MiniaturaTurma';
import { CardMiniaturaTurma } from '../../CardMiniaturaTurma';

export interface BlockProps {
	turmaSemana: ITurmaGradeSemanalDTO;
	grade: { horaInicial: string | null; horaFinal: string | null };
	telaTurma?: boolean;
	idRow: number;
	currentContractEnrollments: IMatriculaDTO[];
	otherEnrollmentsData: IMatriculaDTO[];
}

export const CalendarBlocks = ({
	turmaSemana,
	grade,
	telaTurma,
	idRow,
	currentContractEnrollments,
	otherEnrollmentsData,
}: BlockProps) => {
	const blockFake = (index: number) => {
		return (
			<div
				key={index}
				className={`box-bloco-miniatura d-flex-col-start-star box-space-grid-`}
			>
				<div className="d-flex-row-start-center w-100 gap-3"></div>
				<div className="d-flex-row-between-center box-info w-100">
					<div className="box-sala"></div>
					<div className="box-vaga"></div>
				</div>
			</div>
		);
	};

	const renderDayOfWeekList = (turmas: ITurmaGradeDTO[], day: IDiaSemana) => {
		const blocosFakes = turmas?.map((t) =>
			t?.horarios
				?.filter((h) => h.diaSemana === day)
				?.filter(
					(h) =>
						h.horaInicio?.toString().substring(0, 5) ===
						(parseInt(grade.horaInicial.split(':')[1]) === 0
							? (parseInt(grade.horaInicial.split(':')[0]) - 1)
								.toString()
								.padStart(2, '0') + ':30'
							: parseInt(grade.horaInicial.split(':')[0])
								.toString()
								.padStart(2, '0') + ':00') ||
						h.horaInicio.toString().substring(0, 5) ===
						(parseInt(grade.horaInicial.split(':')[1]) === 0
							? (parseInt(grade.horaInicial.split(':')[0]) - 1)
								.toString()
								.padStart(2, '0') + ':00'
							: (parseInt(grade.horaInicial.split(':')[0]) - 1)
								.toString()
								.padStart(2, '0') + ':30')
				)
				// || h.horaInicio.substring(0, 5) === (parseInt(grade.horaInicial.split(':')[1]) === 0 ? (parseInt(grade.horaInicial.split(':')[0]) - 2).toString().padStart(2, '0') + ':30' : (parseInt(grade.horaInicial.split(':')[0]) - 1).toString().padStart(2, '0') + ':00'))
				?.map((_, index) => blockFake(index))
		);

		const blocosHorarios = turmas?.map((t, indexTurma) =>
			t.horarios
				?.filter((h) => h.diaSemana === day)
				?.filter((h) => h.horaInicio.toString().substring(0, 5) === grade.horaInicial)
				// ?.filter(h => h.horaInicio.substring(0, 5) === grade.horaInicial || (convertStringMinutes(h.horaInicio) <= convertStringMinutes(grade.horaInicial) && convertStringMinutes(h.horaFim) > convertStringMinutes(grade.horaInicial)))
				?.map((hora) => (
					<CardMiniaturaTurma
						diaSemana={day}
						horario={hora.horaInicio.toString()}
						telaTurma={telaTurma}
						key={indexTurma}
						cor={t.turma.negocioCor}
						corSecundaria={t.turma.negocioCorSecundaria}
						variant={t.turma.negocioNomeCurto.toLocaleLowerCase()}
						turma={t?.turma}
						idMiniatura={idRow}
						urlIcone={t.turma.urlIcone}
						codigoIcone={t.turma.codigoIcone}
						currentContractEnrollments={currentContractEnrollments}
						otherEnrollmentsData={otherEnrollmentsData}
					/>
				))
		);

		return blocosFakes ? [...blocosFakes, ...blocosHorarios] : blocosHorarios;
	};

	const renderDayOfWeek = (day: IDiaSemana) => {
		switch (day) {
			case 'SEG':
				return renderDayOfWeekList(turmaSemana?.turmasSegundaFeira, day);
			case 'TER':
				return renderDayOfWeekList(turmaSemana?.turmasTercaFeira, day);
			case 'QUA':
				return renderDayOfWeekList(turmaSemana?.turmasQuartaFeira, day);
			case 'QUI':
				return renderDayOfWeekList(turmaSemana?.turmasQuintaFeira, day);
			case 'SEX':
				return renderDayOfWeekList(turmaSemana?.turmasSextaFeira, day);
			case 'SAB':
				return renderDayOfWeekList(turmaSemana?.turmasSabado, day);
		}
	};

	return (
		<>
			<div className="box-in-calendar">
				<p>{grade.horaInicial}</p>
			</div>
			{WEEK_DAYS_ACRONYMS.map((day, index) => {
				return (
					<Fragment key={index}>
						<div className="box-in-calendar">
							{telaTurma === true && (
								<MiniaturaTurma
									dia={day}
									horaInicio={grade.horaInicial}
									horaFim={grade.horaFinal}
								/>
							)}
							{renderDayOfWeek(day)}
						</div>
					</Fragment>
				);
			})}
		</>
	);
};
