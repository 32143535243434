import { CloseOutlined } from '@mui/icons-material';
import { Button, Checkbox, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../../components/Header';
import Modal from '../../../../components/Modal';
import { useGlobalContext } from '../../../../context/GlobalContext';
import '../../../../global.css';
import { IAlternativaCleanDTO, IQuestaoFullDTO, ITrilhaDTO } from '../../../../models/happy-code-api.model';
import { useNotificationService } from '../../../../services/notification.service';
import { PlusOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import TextArea from 'antd/lib/input/TextArea';
import { useAcademyQuestaoService } from '../../../../services/academy-questao';
import { useAcademyTrilha } from '../../../../services/academy-trilha';
import { set } from 'lodash';

export const AcademyQuestoesCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const history = useHistory();
	const questaoService = useAcademyQuestaoService();
	const trilhaService = useAcademyTrilha();
	const [isOpen, setIsOpen] = useState(false);
	const [trilha, setTrilha] = useState<ITrilhaDTO[]>();
	const [question, setQuestion] = useState<IQuestaoFullDTO>({
		id: null,
		ativo: true,
		descricao: '',
		justificativa: '',
		alternativas: [],
		topicoAula: null,
		trilha: null,
	});

	const { idQuestao } = useParams<any>();

	const findTrilhas = async () => {
		trilhaService.findList().then(({ data }) => {
			setTrilha(data);
		});
	};

	useEffect(() => {
		if (idQuestao) {
			setIsGlobalLoading(true);
			questaoService
				.findFullById({ id: idQuestao })
				.then((response) => {
					setQuestion(response.data);
				})
				.finally(() => setIsGlobalLoading(false));
		}
		findTrilhas();
	}, [idQuestao]);

	const addAlternativa = () => {
		const newAlternativa: IAlternativaCleanDTO = {
			id: null,
			ativo: true,
			descricao: '',
			justificativa: '',
			resposta: false,
		};
		setQuestion((prevQuestion) => ({
			...prevQuestion,
			alternativas: [...prevQuestion.alternativas, newAlternativa],
		}));
	};

	const deleteAlternativa = (index: number) => {
		setQuestion((prevQuestion) => ({
			...prevQuestion,
			alternativas: prevQuestion.alternativas.filter((_, i) => i !== index),
		}));
	};

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idQuestao) {
			questaoService
				.create(question)
				.then(() => {
					notification({
						description: `Questão cadastrada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/academy/gestao-questao');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			questaoService
				.patch(question)
				.then(() => {
					notification({
						description: `Questão editada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/academy/gestao-questao');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve) => {
					loader.file.then((file) => {
						const reader = new FileReader();
						reader.onloadend = () => {
							const base64String = reader.result?.toString().split(',')[1] || '';
							resolve({ default: `data:image/png;base64,${base64String}` });
						};
						reader.readAsDataURL(file);
					});
				});
			},
		};
	}

	function uploadPlugin(editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}

	const submitValidationFields = () => {
		if (!question.descricao) {
			notification({
				description: `Por favor, preencha o campo descrição da questão.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (question.alternativas.length === 0) {
			notification({
				description: `Por favor, adicione ao menos uma alternativa.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		const correctAlternatives = question.alternativas.filter((a) => a.resposta);
		if (correctAlternatives.length === 0) {
			notification({
				description: `Por favor, marque ao menos uma alternativa como correta.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idQuestao ? 'edição' : 'cadastro'} de questão
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label htmlFor="trilha">Trilha relacionada a questão:</label>
							<Select
								style={{ width: '100%' }}
								value={question?.trilha?.id}
								onChange={(value) => {
									setQuestion({
										...question,
										trilha: trilha?.find((trilha) => trilha.id === value),
									});
								}}
							>
								{trilha?.map((trilha) => (
									<Select.Option key={trilha.id} value={trilha.id}>
										{trilha.nome1 + " " + trilha.nome2}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className="input-box">
							<label htmlFor="questao">Questão</label>
							<CKEditor
							 config={{
								extraPlugins: [uploadPlugin],
								mediaEmbed: {
										previewsInData: true, 
								},
								toolbar:{
										removeItems:['heading', 'link', 'bulletedList', 'numberedList', 'alignment', 'decreaseIndent', 'increaseIndent', 'insertTable']
								},
						}}
								editor={ClassicEditor}
								data={question.descricao}
								onChange={(event, editor) => {
									const data = editor.getData();
									setQuestion((prevQuestion) => ({
										...prevQuestion,
										descricao: data,
									}));
								}}
							/>
						</div>
						<Button disabled={question.alternativas.length >= 4} style={{ marginBottom: 10 }} type="primary" onClick={addAlternativa}>
							Adicionar Alternativa <PlusOutlined rev={undefined} />
						</Button>
						{question.alternativas.map((alternativa, index) => (
							<div key={index} className="box-alternativa" style={{ backgroundColor: alternativa.resposta ? '#1ebe5e' : undefined }}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<label>{`Alternativa ${index + 1}`}</label>
									<Button type="primary" danger onClick={() => deleteAlternativa(index)} style={{ marginBottom: 10 }}>
										Excluir Alternativa X
									</Button>
								</div>
								<div className="input-box" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
									<div>
										<label htmlFor={`alternativa${index + 1}`} style={{ marginRight: 10 }}>
											{`Alternativa ${index + 1}`} está correta?
										</label>
										<Checkbox
											checked={alternativa.resposta}
											onChange={(e) => {
												const updatedAlternativas = [...question.alternativas];
												updatedAlternativas[index] = {
													...updatedAlternativas[index],
													resposta: e.target.checked,
												};
												setQuestion((prevQuestion) => ({
													...prevQuestion,
													alternativas: updatedAlternativas,
												}));
											}}
										/>
									</div>
								</div>
								<div className="input-box" style={{ marginRight: 10 }}>
									<label htmlFor={`alternativaDescricao${index + 1}`}>Descrição</label>
									<CKEditor
										config={{
											extraPlugins: [uploadPlugin],
											mediaEmbed: {
												previewsInData: true,
											},
										  toolbar:{
												items:['bold', 'italic'],
										}
										}}
										editor={ClassicEditor}
										data={alternativa.descricao}
										onChange={(event, editor) => {
											const data = editor.getData();
											const updatedAlternativas = [...question.alternativas];
											updatedAlternativas[index] = {
												...updatedAlternativas[index],
												descricao: data,
											};
											setQuestion((prevQuestion) => ({
												...prevQuestion,
												alternativas: updatedAlternativas,
											}));
										}}
									/>
								</div>
								<div className="input-box" style={{ marginRight: 10 }}>
									<label htmlFor={`justificativa${index + 1}`}>Justificativa</label>
									<TextArea
										value={alternativa.justificativa}
										onChange={(e) => {
											const updatedAlternativas = [...question.alternativas];
											updatedAlternativas[index] = {
												...updatedAlternativas[index],
												justificativa: e.target.value,
											};
											setQuestion((prevQuestion) => ({
												...prevQuestion,
												alternativas: updatedAlternativas,
											}));
										}}
									/>
								</div>
							</div>
						))}
						<Button type="primary" onClick={submitValidationFields}>
							{idQuestao ? 'Editar' : 'Cadastrar'} Questão
						</Button>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/academy/gestao-questao`}
			/>
		</div>
	);
};
