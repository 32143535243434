
import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { IAcaArquivoCleanDTO, IAcaArquivoDTO, IAcaArquivoFullDTO, ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO } from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';
interface Props {
	id?: number;
}

export const useAcademyArquivoService = () => {
	const resourceURL = '/aca-arquivo';

	const upload = (file: any): Promise<AxiosResponse<IAcaArquivoDTO>> => {
		const formData = new FormData();
		formData.append('file', file)
		return http.post(`${resourceURL}/upload`, formData);
	};

  const download = (fileName: string, name: string) => {
    return http.get(`${resourceURL}/download/${fileName}?name=${name}`, {
      responseType: 'blob',
    });
  };
	

	return {
		...baseFind<IAcaArquivoCleanDTO, IAcaArquivoDTO, IAcaArquivoFullDTO>(resourceURL),
		...baseFindList<IAcaArquivoCleanDTO, IAcaArquivoDTO, IAcaArquivoFullDTO>(resourceURL),
		...baseFindById<IAcaArquivoCleanDTO, IAcaArquivoDTO, IAcaArquivoFullDTO>(resourceURL),
		...baseCreate<IAcaArquivoCleanDTO, IAcaArquivoDTO, IAcaArquivoFullDTO>(resourceURL),
		...baseUpdate<IAcaArquivoCleanDTO, IAcaArquivoDTO, IAcaArquivoFullDTO>(resourceURL),
		delete: (body: Props) => http.delete(`${resourceURL}/${body.id}`),
		upload,
		download
	};
};
