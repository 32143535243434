import { useEffect, useState } from 'react';
import { Operators } from '../../../../../enum/operators.enum';
import { IRelatorioAulaFullDTO } from '../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../models/predicate.model';
import { PageableResponse } from '../../../../../models/response.model';
import { useRelatorioAulaService } from '../../../../../services/aca-relatorio-aula.service';

export default function useFetchHook() {
	const [dados, setDados] = useState<PageableResponse<IRelatorioAulaFullDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState(1);
	const [loadingTable, setLoadingTable] = useState(false);
	const relatorioAulaService = useRelatorioAulaService();

	const fetchRelatorios = async () => {
		predicate.sort = 'asc'
    predicate.addSort("dataCriacao")
		predicate.addOption('ativo', true);
		setLoadingTable(true);
		relatorioAulaService
			.findFull(predicate)
			.then(({ data }) => setDados(data))
			.finally(() => setLoadingTable(false));
	};

	useEffect(() => {
		const debounce = setTimeout(() => {
			predicate.removeAllOption();
			if (search) {
				predicate.addOption('usuario.login', search, Operators.CONTAINS);
			}
			fetchRelatorios();
		}, 1000); 

		return () => clearTimeout(debounce);
	}, [search]);
	const onPageChange = async (pageEvent: number) => {
		setLoadingTable(true);
		setPage(pageEvent + 1);
		predicate.setPage(pageEvent);
		await fetchRelatorios();
	};

	return {
		dados,
		search,
		page,
		loadingTable,
		setSearch,
		onPageChange,
		setDados,
		onchange,
		fetchRelatorios,
	};
}
