import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { MenuDetalheAluno } from '../../components/MenuDetalheAluno';
import NewMenu from '../../components/NewMenu';
import { useAlunoContext } from '../../context/AlunoContext';
import { TipoDocumentoEnum } from '../../enum/tipoDocumento.enum';

import { ContatoEnum } from '../../models/pessoa.model';
import { formattedDate } from '../../util/format';
import './style.css';
import { useGlobalContext } from '../../context/GlobalContext';

interface Param {
	id?: string;
}

export const AlunosCreateStep5: React.FunctionComponent = () => {
	const [open, setOpen] = useState({});
	const { aluno, setRevisao } = useAlunoContext();
	const { id } = useParams<Param>();
	const history = useHistory();
	const { setIsGlobalLoading, unidade, usuario } = useGlobalContext();

	const goTo = (url) => history.push(url);

	useEffect(() => {

		/*
		Este trecho deve ser descartado. O tratamento do responsável foi alterado no backend.

		aluno.pessoa.pessoaResponsaveis = aluno.pessoa.pessoaResponsaveis.filter(
			pessoaXresp => pessoaXresp.responsavel.cpf
		);
		*/

		setRevisao(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClick = (id) => {
		setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
	};

	const isDocumentoEnviado = (tipoDocumentoEnum: TipoDocumentoEnum) => {
		return aluno?.pessoa?.documentos?.find(
			(documento) => documento.tipoDocumentoId === tipoDocumentoEnum
		);
	};

	return (
		<div className="container-fondo" style={{ paddingTop: 0 }}>
			{/* <Header /> */}
			<NewMenu selecionado={10} />
			<MenuDetalheAluno id={Number(id) || aluno.id} />
			<div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
				<div className="franquia-row">
					<h5>Revisão de Dados</h5>
					<div className="row-revisao">
						<ul className="menu-revisao-detalhe">
							<li className="selected" style={{ width: '25%' }}>
								<a href="#dadoBasicos">Dados Básicos</a>
							</li>
							<li style={{ width: '25%' }}>
								<a href="#contato">Contato</a>
							</li>
							<li style={{ width: '25%' }}>
								<a href="#responsaveis">Responsáveis</a>
							</li>
							<li style={{ width: '25%' }}>
								<a href="#documentos">Documentos</a>
							</li>
						</ul>
						<div className="box-revisao">
							<div id="dadosBasicos" className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/alunos/create/step-1')}
								>
									Editar
								</Button>
								<h4>Dados Basicos</h4>
								<div className="box-aluno">
									<Avatar size={115} arquivo={aluno?.pessoa?.foto} />
									<div className="dados-colaborador">
										<div className="nome">
											<h5>Nome Completo</h5>
											<span style={{ fontSize: 18 }}>{aluno.pessoa.nome}</span>
										</div>
										<div className="dados-franquia">
											<div className="cpf">
												<h6>CPF</h6>
												<span> {aluno.pessoa.cpf}</span>
											</div>
											<div className="cpf">
												<h6>Data de Nascimento</h6>
												<span>
													{formattedDate(aluno.pessoa.dataNascimento)}
												</span>
											</div>
										</div>
										<div className="dados-franquia">
											<div className="cpf">
												<h6>Sexo</h6>
												<span>{aluno.pessoa.genero?.descricao || 'N/A'}</span>
											</div>
											<div className="cpf">
												<h6>Status</h6>
												<span>{aluno.ativo ? 'ATIVO' : 'INATIVO'}</span>
											</div>
										</div>
										<div className="dados-franquia">
											<div className="cpf" style={{ marginRight: 45 }}>
												<h6>Observações</h6>
												<span>{aluno.observacao}</span>
											</div>
										</div>
										{usuario?.funcao === "TEC" && (
											<div className="dados-franquia">
												<div className="teste" style={{ marginRight: 45 }}>
													<h6>Aluno teste</h6>
													<span>{aluno.pessoa.pessoaTeste ? "Sim" : "Não"}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div id="responsaveis" className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/alunos/create/step-3')}
								>
									Editar
								</Button>
								<h4>Responsáveis</h4>
								{aluno.pessoa?.pessoaResponsaveis &&
									aluno.pessoa?.pessoaResponsaveis.map((responsavel, index) => (
										<div className="resposavel-revisao" key={index}>
											<h4>Responsável {index + 1}</h4>
											<div className="nome">
												<h5>Nome Completo</h5>
												<span style={{ fontSize: 18 }}>
													{responsavel.responsavel.nome}
												</span>
											</div>
											{usuario?.funcao === "TEC" && (
												<div className="dados-franquia">
													<div className="teste" style={{ marginRight: 45 }}>
														<h6>Responsavel teste</h6>
														<span>{aluno.pessoa.pessoaTeste ? "Sim" : "Não"}</span>
													</div>
												</div>
											)}
											<div className="dados-franquia">
												<div className="cpf">
													<h6>CPF</h6>
													<span>{responsavel.responsavel.cpf}</span>
												</div>
												<div className="cpf">
													<h6>Data de Nascimento</h6>
													<span>
														{formattedDate(
															responsavel.responsavel.dataNascimento
														)}
													</span>
												</div>
											</div>
											<div className="dados-franquia">
												<div className="cpf">
													<h6>Grau Parentesco</h6>
													<span>{responsavel.grauParentesco}</span>
												</div>
											</div>

											<div
												style={{ display: open[index] ? 'block' : 'none' }}
												id="example-collapse-text"
												className="collapsedText"
											>
												<>
													<div className="dados-franquia">
														<div className="cpf">
															<h6>Empresa</h6>
															<span>
																{responsavel.responsavel.descricaoEmpresa}
															</span>
														</div>
														<div className="cpf">
															<h6>Profissão</h6>
															<span>
																{responsavel.responsavel.descricaoProfissao}
															</span>
														</div>
													</div>
													<h4>Endereço</h4>
													<div className="dados-franquia">
														<div className="cpf">
															<h6>CEP</h6>
															<span>
																{responsavel.responsavel.endereco?.cep}
															</span>
														</div>
													</div>
													<div className="dados-franquia">
														<div className="cpf">
															<h6>Endereço</h6>
															<span>
																{responsavel.responsavel.endereco?.logradouro}
															</span>
														</div>
													</div>
													<div className="dados-franquia">
														<div className="cpf">
															<h6>Número</h6>
															<span>
																{responsavel.responsavel.endereco?.numero}
															</span>
														</div>
														<div className="cpf">
															<h6>Complemento</h6>
															<span>
																{responsavel.responsavel.endereco?.complemento}
															</span>
														</div>
													</div>
													<h4>Contato</h4>
													<div className="dados-franquia">
														<div className="cpf">
															<h6>Telefone 1</h6>
															<span>
																{
																	responsavel.responsavel.contatos?.find(
																		(c) => c.tipoContatoId === ContatoEnum.CEL
																	)?.descricao
																}
															</span>
														</div>
														<div className="cpf">
															<h6>Telefone 2</h6>
															<span>
																{
																	responsavel.responsavel.contatos?.find(
																		(c) => c.tipoContatoId === ContatoEnum.TEL
																	)?.descricao
																}
															</span>
														</div>
													</div>
													<div className="dados-franquia">
														<div className="cpf">
															<h6>E-mail</h6>
															<span>
																{
																	responsavel.responsavel.contatos?.find(
																		(c) => c.tipoContatoId === ContatoEnum.EPS
																	)?.descricao
																}
															</span>
														</div>
													</div>
												</>
											</div>
											<a onClick={() => handleClick(index)}>
												<p className="dethales-aluno">
													Mostrar {open[index] ? 'menos' : 'mais'} detalhes
												</p>
											</a>
										</div>
									))}
							</div>
							{/* <div className="line" style={{ marginBottom: 20 }}></div>
							<div id="contato" className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/alunos/create/step-2')}
								>
									Editar
								</Button>
								<h4>Contato</h4>
								<div className="dados-franquia">
									<div className="cpf">
										<h6>TELEFONE</h6>
										<span>
											{aluno.pessoa.contatos?.find(
												(c) => c.tipoContatoId === ContatoEnum.TEL
											)?.descricao || 'N/A'}
										</span>
									</div>
								</div>
								<div className="dados-franquia">
									<div className="cpf">
										<h6>E-mail pessoal</h6>
										<span>
											{aluno.pessoa.contatos?.find(
												(c) => c.tipoContatoId === ContatoEnum.EPS
											)?.descricao || 'N/A'}
										</span>
									</div>
									<div className="cpf">
										<h6>E-mail de aluno</h6>
										<span>
											{aluno.pessoa.contatos?.find(
												(c) => c.tipoContatoId === ContatoEnum.EAL
											)?.descricao || 'N/A'}
										</span>
									</div>
								</div>
							</div> */}
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div id="documentos" className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/alunos/create/step-4')}
								>
									Editar
								</Button>
								<h4>Documentos</h4>
								<div className="documentos-aluno">
									<div className="estado">
										<div
											className={
												isDocumentoEnviado(TipoDocumentoEnum.DIA)
													? 'check-doc'
													: 'check-doc-red'
											}
										>
											{isDocumentoEnviado(TipoDocumentoEnum.DIA) ? (
												<Unicons.UilCheck size="16" color="#FFFFFF" />
											) : (
												<Unicons.UilTimes size="16" color="#FFFFFF" />
											)}
										</div>
										<p>Documento de identificação do aluno</p>
									</div>
								</div>
								<div className="documentos-aluno">
									<div className="estado">
										<div
											className={
												isDocumentoEnviado(TipoDocumentoEnum.DIRP)
													? 'check-doc'
													: 'check-doc-red'
											}
										>
											{isDocumentoEnviado(TipoDocumentoEnum.DIRP) ? (
												<Unicons.UilCheck size="16" color="#FFFFFF" />
											) : (
												<Unicons.UilTimes size="16" color="#FFFFFF" />
											)}
										</div>
										<p>Documento de identificação do responsável principal</p>
									</div>
								</div>
								<div className="documentos-aluno">
									<div className="estado">
										<div className={
											isDocumentoEnviado(TipoDocumentoEnum.CPR)
												? 'check-doc'
												: 'check-doc-red'
										}>
											{isDocumentoEnviado(TipoDocumentoEnum.CPR) ? (
												<Unicons.UilCheck size="16" color="#FFFFFF" />
											) : (
												<Unicons.UilTimes size="16" color="#FFFFFF" />
											)}
										</div>
										<p>Comprovante de residência</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link to="/alunos/create/step-4">
				<div className="botao-voltar-revisao">
					<Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>
						Voltar
					</Button>
				</div>
			</Link>
		</div>
	);
};
