import { ColumnsType } from 'antd/lib/table';
import { IRelatorioAulaFullDTO } from '../../../../models/happy-code-api.model';
import { formattedDate } from '../../../../util/format';

export const columns: ColumnsType<IRelatorioAulaFullDTO> = [
	{
		title: 'Mentor',
		dataIndex: 'usuario',
		key: 'usuario',
		render: (usuario) => usuario?.usuario || '-',
	},
	{
		title: 'Email',
		dataIndex: 'usuario',
		key: 'email',
		render: (usuario) => usuario?.login || '-',
	},
	{
		title: 'Título',
		dataIndex: 'titulo',
		key: 'titulo',
	},
	{
		title: 'Ano-Turma',
		dataIndex: 'anoTurma',
		key: 'anoTurma',
	},
	{
		title: 'Produto do relatório',
		dataIndex: 'produtoRelatorioAula',
		key: 'produtoRelatorioAula',
		render: (produto) => produto?.descricao || '-',
	},
	{
		title: 'Arquivos',
		dataIndex: 'arquivos',
		key: 'arquivos',
		render: (arquivos) =>
			arquivos?.map((arquivo, index) => {
				const nomeArquivo = arquivo.codigo;
				const [nome, extensao] = nomeArquivo.split(/\.([^.]+)$/);
				const nomeResumido = nome.length > 10 ? `${nome.slice(0, 15)}...` : nome;

				return (
					<div key={arquivo.id}>
						{index + 1} - {nomeResumido}.{extensao}
					</div>
				);
			}) 
	},
	{
		title: 'Data de envio',
		dataIndex: 'dataCriacao',
		key: 'dataCriacao',
		render: (dataCriacao) => formattedDate(dataCriacao),
	},
];
