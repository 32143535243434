import AddCircleIcon from '@mui/icons-material/AddCircle';
import { OffersFiltersObject } from '../../interfaces/offer/offers-filters-object.interface.';
import { removeAcentos } from '../../util/format';
import './style.css';

interface CheckedStateProps {
	code: boolean;
	money: boolean;
	speech: boolean;
	english: boolean;
	curta: boolean;
	colonia: boolean;
	cb: boolean;
	escolas: boolean;
	antigo: boolean;
	onecode: boolean;
	onecom: boolean;
}

interface BoxOfferProps {
	color: string;
	name: string;
	onClick: () => void;
	disabled?: boolean;
	isCheckedState: CheckedStateProps;
	offerFilterActions: OffersFiltersObject;
}

export const BoxOffer = ({
	color,
	name,
	onClick,
	disabled,
	isCheckedState,
	offerFilterActions,
}: BoxOfferProps) => {
	//const checkedBoxSelectedClass = checked ? `box-offer-${variant}-selected` : `box-offer-${variant}`;
	return (
		<button
			disabled={disabled}
			onClick={offerFilterActions[removeAcentos(name).toLowerCase()]}
			style={{
				backgroundColor:
					isCheckedState[removeAcentos(name).toLowerCase()] == true ? color : 'gray',
			}}
			className={`box-offer`}
		>
			{name}
			{/* <img src={variantImageMap[variant]} alt={`${variant} image`} /> */}
			<AddCircleIcon sx={{ color: 'white' }} />
		</button>
	);
};
