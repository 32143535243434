import './course-card.styles.scss';
import { ICobrancaDTO, IMatriculaCustomDTO } from '../../../../../../models/happy-code-api.model';
import useUpgradeIsAvailable from '../../../../hooks/use-upgrade-is-available.hook';
import useCourseCard from './use-course-card.hook';
import { useEffect, useState } from 'react';
import { useCobrancaService } from '../../../../../../services/cobranca.service';
import { AxiosResponse } from 'axios';
import { TipoStatusCobranca } from '../../../../../../enum/tipoStatusCobranca';

interface CourseCardProps {
	course: IMatriculaCustomDTO;
	onUpgradeAvailable?: () => void;
}

export default function CourseCard({ course, onUpgradeAvailable }: CourseCardProps) {
	const [cobrancas, setCobrancas] = useState<ICobrancaDTO[] | null>(null);
	const cobrancaService = useCobrancaService();
	const code = course?.turmaCurso?.descricao || course?.turmaCurriculoNegocioDescricao;
	const classroom = course.turma?.descricao;
	const idFinanceiro = course?.financeiro?.id;
	const idFinanceiroOrigem = course?.financeiro?.financeiroOrigem;

	const { courseCardBorderLeftColor, navigateToDetalheMatriculaPage } = useCourseCard();

	const { upgradeButtonVisible } = useUpgradeIsAvailable({
		idFinanceiro,
	});

	const getCourseCardBorderLeftColor = courseCardBorderLeftColor[code] || 'var(--primary-color)';

	useEffect(() => {
		if (course?.financeiro?.numeroSorteio && idFinanceiro) {
			cobrancaService.findCobrancasByIdFinanceiro(idFinanceiro).then((response) => setCobrancas(response.data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idFinanceiro]);

	useEffect(() => {
		if (upgradeButtonVisible && onUpgradeAvailable){
			onUpgradeAvailable();	
		} 
	},[upgradeButtonVisible, onUpgradeAvailable])

	const checkForDateAndCourseType = (dataFinal:Date, dataInicial:Date) => {
		const isRegular = course?.turma?.curriculoNegocioTipoCurriculoCodigo === "RGL"
		return dataFinal > new Date(cobrancas[0].dataPagamento) && dataInicial < new Date && cobrancas[0].dataPagamento && isRegular
	}
	const verifySorterNumberIsAvalible = () => {
		const dataFinal = new Date('2025-04-01');
		const dataInicial = new Date('2025-01-01');

		if (
			cobrancas &&
			cobrancas.some((cobranca) => (cobranca.parcela === 0 || cobranca.parcela === 1) && cobranca.statusCobranca.codigo === TipoStatusCobranca.CODIGO_PAGO)
		) {
			if (checkForDateAndCourseType(dataFinal,dataInicial)) {
				return true;
			}
		}
		return false;
	};


	const renderSorterNumber = () => {
		if (verifySorterNumberIsAvalible()) {
			return (
				<p className={'course-infos__classroom-text-small'}>
					Número do sorteio: <strong>{course?.financeiro?.numeroSorteio}</strong>
				</p>
			);
		}
		return null;
	};

	return (
		<div
			onClick={() => navigateToDetalheMatriculaPage(course)}
			className={'course-card-wrapper'}
			style={{ borderLeft: `8px solid ${getCourseCardBorderLeftColor}` }}
		>
			<div className={'course-card-wrapper__course-infos'}>
				<div className={'course-infos__financeiro-code'}>
					<p>#{idFinanceiro}</p>
					<p>{code}</p>
				</div>
				<div className={'course-infos__financeiro-code'}>
					{idFinanceiroOrigem && <small className={'course-infos__classroom-text'}>Contrato Anterior: #{idFinanceiroOrigem} </small>}
					<small className={'course-infos__classroom-text'}>{classroom}</small>
				</div>
				{renderSorterNumber()}
			</div>

			{upgradeButtonVisible && <div className={'course-card-wrapper__upgrade-indicator'}>Upgrade disponível</div>}
		</div>
	);
}
