
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useAlunoService } from '../../../services/aluno.service';
import { useNotificationService } from '../../../services/notification.service';
import { IGeneroCleanDTO, IEnderecoCleanDTO, IPessoaResponsavelCustomDTO, IDocumentoCleanDTO, IArquivoCleanDTO, IContatoCleanDTO, IUnidadePessoaCleanDTO, IUnidadeCleanDTO, IMatriculaCustomDTO, IStatusAluno } from '../../../models/happy-code-api.model';

export const useUpdateAluno = (aluno, setAluno) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const alunoService = useAlunoService();
    const notification = useNotificationService();
    const history = useHistory();

    const updateAluno = async (p0: { pessoa: { pessoaTeste: boolean; trocarResponsavel: boolean; genero: IGeneroCleanDTO; endereco: IEnderecoCleanDTO; pessoaResponsaveis: IPessoaResponsavelCustomDTO[]; documentos: IDocumentoCleanDTO[]; id: number; cpf: string; rg: string; email: string; descricaoEmpresa: string; observacao: string; descricaoProfissao: string; descricaoRestricao: string; dataNascimento: Date; dataAdmissao: Date; permiteUsoImagem: boolean; nome: string; identificadorPagarMe: string; foto: IArquivoCleanDTO; contatos: IContatoCleanDTO[]; unidadePessoas: IUnidadePessoaCleanDTO[]; ativo: boolean; }; leadId: number; unidade: IUnidadeCleanDTO; matriculas: IMatriculaCustomDTO[]; id: number; emailAcesso: string; statusAluno: IStatusAluno; observacao: string; restricao: string; anoEscolar: number; escola: string; ativo: boolean; }) => {
        setIsGlobalLoading(true);
        try {
            aluno.pessoa.pessoaResponsaveis.forEach((resp) => {
                const contatos = resp.responsavel.contatos.filter((cont) => cont.descricao && cont.descricao.trim() > ' ');
                resp.responsavel.contatos = contatos;
            });

            const { data } = await alunoService.patch(aluno);
            if (data) {
                const { data: alunoData } = await alunoService.findCustomById(data.id);
                setAluno(alunoData);
                history.push('/alunos/detalhe');
                notification({
                    description: 'Dados do aluno alterados!',
                    type: 'success',
                    message: 'Sucesso!',
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsGlobalLoading(false);
        }
    };

    return { updateAluno };
};
