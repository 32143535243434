/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Row, Table } from 'antd';
import { toArray } from 'lodash';
import { useEffect } from 'react';
import MenuSidebarAndContainer from '../../../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { ExportToExcel } from '../../../../util/exportToExcel';
import { formattedDate } from '../../../../util/format';
import ModalDowloadFile from './components/dowload-file';
import { useDadosExcel } from './hooks/export-to-excel';
import useClickTable from './hooks/use-click-table';
import useFetchHook from './hooks/use-fetch-hook';
import { columns } from './table-columns';

export default function RelMinhasAulasHappy() {
	const { setIsGlobalLoading } = useGlobalContext();

	const { dados, page, search, loadingTable, onPageChange, fetchRelatorios, setSearch } = useFetchHook();
	const { dataExcel, fetchDataForExcel } = useDadosExcel();
	const { modalOpen, selectedFiles, handleRowClick, closeModal } = useClickTable();

	useEffect(() => {
		setIsGlobalLoading(true);
		Promise.all([fetchRelatorios(), fetchDataForExcel()]).finally(() => setIsGlobalLoading(false));
	}, []);

	return (
		<MenuSidebarAndContainer itemMenuSelected={18}>
			<div style={{ padding: '1rem' }}>
				<h3 className="title">Relatório de registro de aulas</h3>
			</div>
			<Row
				justify="space-between"
				align="middle"
				style={{
					backgroundColor: '#fff',
					padding: '12px',
				}}
			>
				<Col span={9}>
					<span style={{ display: 'block', marginBottom: 4 }}>Buscar por email do mentor:</span>
					<Input placeholder="Digite o email do mentor" value={search} onChange={(e) => setSearch(e.target.value)} />
				</Col>
				<Col span={2}>
					<ExportToExcel
						apiData={toArray(dataExcel ?? []).map((d) => {
							return {
								Usuario: d?.usuario?.usuario,
								Titulo: d?.titulo,
								Descricao: d?.descricao,
								AnoTurma: d?.anoTurma,
								Produto: d?.produtoRelatorioAula?.descricao,
								DataCriacao: formattedDate(d?.dataCriacao),
								arquivos: d?.arquivos?.map((arquivo) => arquivo?.codigo).join(', '),
							};
						})}
						fileName="relatorio_aulas"
					/>
				</Col>
			</Row>
			<Table
				onRow={(record) => ({
					onClick: () => handleRowClick(record),
				})}
				loading={loadingTable}
				bordered
				pagination={{
					current: page,
					total: dados?.totalElements,
					onChange: (page) => onPageChange(page - 1),
				}}
				dataSource={dados?.content}
				columns={columns}
				rowKey="id"
			/>
			<ModalDowloadFile open={modalOpen} arquivos={selectedFiles} onClose={closeModal} />
		</MenuSidebarAndContainer>
	);
}
