import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Progress, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useNegocioContext } from '../../context/NegocioContext';
import '../../global.css';
import { IArquivoCleanDTO, ITipoCurriculoCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNegocioService } from '../../services/negocio.service';
import { useTipoCurriculoService } from '../../services/tipo-curriculo.service';
import StepsCreate from './steps-create';
import './style.css';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadChangeParam } from 'antd/lib/upload';
import { useNotificationService } from '../../services/notification.service';
import { UploadFile } from 'antd/lib/upload/interface';
import { token } from '../../util/store';

const { Option } = Select;

const NegocioCreateStep1: React.FunctionComponent = () => {
	const { negocio, setNegocio } = useNegocioContext();

    const notification = useNotificationService();
	const [isOpen, setIsOpen] = useState(false);
	const negocioService = useNegocioService();
	const tipoCurriculoService = useTipoCurriculoService();
	const [listTipoCurriculo, setListTipoCurriculo] = useState<ITipoCurriculoCleanDTO[]>();

	const findTipoCurriculo = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		tipoCurriculoService.findCleanList(predicate).then((response) => setListTipoCurriculo(response.data));
	};

	const fetchNegocio = () => {
		negocioService.findFullById({ id: negocio?.id }).then((response) => setNegocio(response.data));
	};

	useEffect(() => {
		findTipoCurriculo();
		if (negocio?.id) {
			fetchNegocio();
		}
	}, []);

	const history = useHistory();
	const onFinish = () => {
		history.push('/negocio');
	};

	const proximo = () => {
		history.push('/negocio/create/step-2');
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response: IArquivoCleanDTO = file.response;
			setNegocio({ ...negocio, icone: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo`,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setNegocio({ ...negocio, icone: null });
			notification({
				description: `${file.name} removido com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
		}
	};

	const beforeUpload = (file: UploadFile) => {
		if (file.size > 20 * 1024 * 1024) {
			notification({
				description: `O tamanho do arquivo deve ser menor que 20MB!`,
				type: 'warning',
				message: 'Atenção!',
			});
			file.status = 'error';
			file.response = 'O tamanho do arquivo deve ser menor que 20MB!';

			return false;
		}
		return true;
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress className="progress-user" percent={16} strokeLinecap="square" showInfo={false} />
			</div>
			<StepsCreate current={0} />
			<div className="franquia">
				<div className="user-row">
					<Form
						name="basic"
						// labelCol={{ span: 8 }}
						// wrapperCol={{ span: 16 }}
						initialValues={{ remember: true }}
						onFinish={onFinish}
					>
						<h3 id="taxas-h3">Insira o nome do negocio</h3>
						{/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
						<div className="input-box">
							<p>Nome do negocio</p>
							<Input value={negocio?.descricao} id="nome" onChange={(e) => setNegocio({ ...negocio, descricao: e.target.value })} />
						</div>
						<div className="input-box">
							<p>Nome curto</p>
							<Input value={negocio?.nomeCurto} id="nomeCurto" onChange={(e) => setNegocio({ ...negocio, nomeCurto: e.target.value })} />
						</div>
						<div className="input-box">
							<p>Negócio Cor</p>
							<Input
								id="cor"
								type="color"
								className="w-5"
								value={negocio?.cor || '#ffffff'}
								onChange={(e) => setNegocio({ ...negocio, cor: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<p>Negócio Cor Secundária</p>
							<Input
								id="corSecundaria"
								type="color"
								className="w-5"
								value={negocio?.corSecundaria || '#ffffff'}
								onChange={(e) => setNegocio({ ...negocio, corSecundaria: e.target.value })}
							/>
						</div>
						<div className="input-box" id="box-aluno">
							<p>
								Tipo do Currículo<span id="ob">*</span>
							</p>
							<Select
								style={{ width: '100%' }}
								placeholder="Selecione o produto"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={negocio?.tipoCurriculo?.id}
								value={negocio?.tipoCurriculo?.id}
								onChange={(e) => setNegocio({ ...negocio, tipoCurriculo: { ...negocio.tipoCurriculo, id: e } })}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{listTipoCurriculo &&
									listTipoCurriculo?.map((tipoCurriculo) => (
										<Option key={tipoCurriculo.id} value={tipoCurriculo.id} text={tipoCurriculo?.descricao}>
											{tipoCurriculo?.descricao}
										</Option>
									))}
							</Select>
						</div>

						<div className="input-box">
							<p>Código do Negócio</p>
							<Input maxLength={3} value={negocio?.codigo} id="descricao" onChange={(e) => setNegocio({ ...negocio, codigo: e.target.value.toUpperCase() })} />
						</div>
						<div className="input-box">
							<p>Habilitar tranferência de turma para qualquer negócio:</p>
							<Radio.Group
								defaultValue={negocio?.transferencia}
								value={negocio?.transferencia}
								onChange={(e) => setNegocio({ ...negocio, transferencia: e.target.value })}
							>
								<Radio value={false}>Não</Radio>
								<Radio value={true}>Sim</Radio>
							</Radio.Group>
						</div>
						<div className="input-box">
							<p>Hora aula (em minutos)</p>
							<InputNumber minLength={0} value={negocio?.horaSemanal} id="descricao" onChange={(e) => setNegocio({ ...negocio, horaSemanal: e })} />
						</div>
						<div className="input-box">
							<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
								<label>Arquivo</label>
								<small style={{ marginTop: 10 }}>{negocio?.icone?.codigo}</small>
								<div style={{ textAlign: 'center', width: '100%' }}>
									<Dragger
										headers={{ Authorization: `Bearer ${token()}` }}
										action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`}
										onChange={onChange}
										maxCount={1}
										beforeUpload={beforeUpload}
									>
										<p className="ant-upload-drag-icon">
											<InboxOutlined rev={undefined} />
										</p>
										<p className="ant-upload-text">Clique ou arraste o arquivo para este área</p>
										<p className="ant-upload-hint">Tamanho máximo do arquivo suportado: 20MB</p>
									</Dragger>
								</div>
							</div>
						</div>
						<div className="botoes">
							<Link to="/negocio">
								<Button className="button-second" id="voltar" style={{ width: 345 }}>
									Voltar
								</Button>
							</Link>
							<Button onClick={proximo} type="primary" htmlType="submit" id="proximo" className="button-primary" style={{ width: 345 }}>
								proximo
							</Button>
						</div>
					</Form>
				</div>
			</div>
			<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a criação desse negocio?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/negocio"
			/>
		</div>
	);
};

export default NegocioCreateStep1;
