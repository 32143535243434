import { AxiosResponse } from 'axios';
import {
  IRelatorioAulaCleanDTO,
  IRelatorioAulaDTO,
  IRelatorioAulaFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';
import http from '../helpers/http-common';
import { PageableResponse } from '../models/response.model';

export const useRelatorioAulaService = () => {
  const resourceURL = '/aca-relatorio-aula';

  const findFullByIdCustom = async (id:number):Promise<AxiosResponse<IRelatorioAulaFullDTO>> => {
    return http.get(`${resourceURL}/full-custom/${id}`);
  }

  const inativateRelatorio = async (id:number):Promise<AxiosResponse<void>> => {
    return http.patch(`${resourceURL}/inativar-relatorio/${id}`);
  }

  const findFullByUserId = async (id:number):Promise<AxiosResponse<PageableResponse<IRelatorioAulaFullDTO>>> => {
    return http.get(`${resourceURL}/full-by-user-id/${id}`);
  }

  return {
        ...baseFind<IRelatorioAulaCleanDTO, IRelatorioAulaDTO, IRelatorioAulaFullDTO>(resourceURL),
        ...baseFindList<IRelatorioAulaCleanDTO, IRelatorioAulaDTO, IRelatorioAulaFullDTO>(resourceURL),
        ...baseFindById<IRelatorioAulaCleanDTO, IRelatorioAulaDTO, IRelatorioAulaFullDTO>(resourceURL),
        ...baseCreate<IRelatorioAulaCleanDTO, IRelatorioAulaDTO, IRelatorioAulaFullDTO>(resourceURL),
        ...baseUpdate<IRelatorioAulaCleanDTO, IRelatorioAulaDTO, IRelatorioAulaFullDTO>(resourceURL),
      findFullByIdCustom,
      inativateRelatorio,
      findFullByUserId
      }
};
