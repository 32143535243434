/* eslint-disable react-hooks/rules-of-hooks */
import { Button } from 'antd';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { IAcaArquivoCleanDTO } from '../../../../../models/happy-code-api.model';
import { useAcademyArquivoService } from '../../../../../services/academy-arquivo';

interface ModalDowloadProps {
	arquivos: IAcaArquivoCleanDTO[];
	open: boolean;
  onClose: () => void;
}

export default function ModalDowloadFile({ arquivos, open, onClose }: ModalDowloadProps) {
	const { setIsGlobalLoading } = useGlobalContext();
	const arquivoService = useAcademyArquivoService();

	const handleDowload = async (arquivo: IAcaArquivoCleanDTO) => {
		setIsGlobalLoading(true);
		try {
			const response = await arquivoService.download(
        arquivo.url,
        arquivo.codigo,
      );
			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});
			const tempLink = document.createElement('a');
			tempLink.href = window.URL.createObjectURL(blob);
			tempLink.setAttribute('download', arquivo.codigo);
			tempLink.click();
			window.URL.revokeObjectURL(tempLink.href);
		} catch (error) {
			console.error(error);
		} finally {
			setIsGlobalLoading(false);
		}
	};
	return (
		<>
			{open && (
				<div className="container-modal-payment-change">
					<div className="modal-change-payment">
						<div className="header-payment">
							<h3>Baixar Arquivos</h3>
							<p>Selecione o arquivo que deseja baixar</p>
						</div>
						<div className="container-payment">
							{arquivos?.map((arquivo) => (
								<div key={arquivo.id} onClick={() => handleDowload(arquivo)} className={'payment-item'} style={{
                  width:'220px',
                  height:'120px',
                  lineBreak: 'anywhere'
                }}>
									<span>{arquivo.codigo}</span>
								</div>
							))}
						</div>
						<div className="footer-payment">
							<Button id="button2" onClick={() => onClose()} className={'button-primary'} style={{ marginLeft: 8, minWidth: 145 }}>
								Voltar
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
