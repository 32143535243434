import { useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { INegocioUnidadeCustomDTO } from '../../../models/happy-code-api.model';
import { useNegocioUnidadeService } from '../../../services/negocio-unidade.service';

interface OffersObjectArray {
	color: string;
	name: string;
	id: number;
	onClick: () => void;
	disabled?: boolean;
}

export default function useOffersObject() {
	//const [offersObjectArray, setOffersObjectArray] = useState<OffersObjectArray[]>();
	const { unidade } = useGlobalContext();
	const negocioUnidadeService = useNegocioUnidadeService();
	const [negocioUnidades, setNegocioUnidades] = useState<INegocioUnidadeCustomDTO[]>();

	const offersObjectArray = negocioUnidades?.map(
		(un) =>
			({
				color: un.negocio.cor,
				name: un.negocio.nomeCurto,
				id: un.negocio.tipoCurriculo.id,
				disabled: !un.negocio.ativo,
			} as OffersObjectArray)
	);

	useMemo(() => {
		if (!unidade?.id) return;

		negocioUnidadeService.findCustomDTO(unidade.id).then((response) => setNegocioUnidades(response.data));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id]);

	return {
		offersObjectArray,
	};
}
