import { CloseOutlined } from "@mui/icons-material";
import { Button, DatePicker, Input, InputNumber, Radio, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { PresentImage } from "../../../../components/ApresentarImagem";
import ContadorCaracteres from "../../../../components/ContadorCaracteres";
import Header from "../../../../components/Header";
import Modal from '../../../../components/Modal';
import { useGlobalContext } from "../../../../context/GlobalContext";
import { TipoProduto } from "../../../../enum/tipoProduto.enum";
import "../../../../global.css";
import {
    IAcaCursoDTO,
    IModalidadeCleanDTO,
    INegocioCleanDTO,
    IProdutoEscolaCleanDTO,
    ISituacaoCursoCleanDTO,
    ITipoCursoCleanDTO,
    IUsuarioDTO
} from "../../../../models/happy-code-api.model";
import { Predicate } from "../../../../models/predicate.model";
import { useAcademyArquivoService } from "../../../../services/academy-arquivo";
import { useAcademyCurso } from "../../../../services/academy-curso";
import { useModalidadeService } from "../../../../services/modalidade.service";
import { useNegocioService } from "../../../../services/negocio.service";
import { useNotificationService } from "../../../../services/notification.service";
import { useProdutoEscolaService } from "../../../../services/produto-escola.service";
import { useSituacaoCursoService } from "../../../../services/situacao-curso";
import { useTipoCursoService } from "../../../../services/tipo-curso.service";
import { useUsuarioService } from "../../../../services/usuario.service";
import { validateFileExtension } from "../../../../util/util";

const CursoCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const notification = useNotificationService();
    const cursoService = useAcademyCurso();
    const arquivoService = useAcademyArquivoService();
    const modalidadeService = useModalidadeService();
    const negocioService = useNegocioService();
    const tipoCursoService = useTipoCursoService();
    const usuarioService = useUsuarioService();
    const situacaoCursoService = useSituacaoCursoService();
    const produtoEscolaService = useProdutoEscolaService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [curso, setCurso] = useState<IAcaCursoDTO>();
    const { idTrilha, idCurso } = useParams<any>()
    const [sendFile, setSendFile] = useState<File | null>(null);
    const [predicate] = useState<Predicate>(new Predicate());
    const [modalidades, setModalidades] = useState<IModalidadeCleanDTO[]>([]);
    const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);
    const [situacaoCursos, setSituacaoCursos] = useState<ISituacaoCursoCleanDTO[]>([]);
    const [tipoCursos, setTipoCursos] = useState<ITipoCursoCleanDTO[]>([]);
    const [validaArquivo, setValidaArquivo] = useState<boolean>(false);
    const [usuarios, setUsuarios] = useState<IUsuarioDTO[]>();
    const [produtoEscola, setProdutoEscola] = useState<IProdutoEscolaCleanDTO[]>([]);

    const findCursos = () => {
        setIsGlobalLoading(true);
        cursoService.findById({ id: idCurso })
            .then((response) => {
                setCurso(response.data);
                setValidaArquivo(response.data.capaCurso ? true : false);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findModalidade = () => {
        setIsGlobalLoading(true);
        modalidadeService.findList(predicate)
            .then((response) => {
                setModalidades(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findNegocio = () => {
        setIsGlobalLoading(true);
        negocioService.findList(predicate)
            .then((response) => {
                setNegocios(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findSituacaoCurso = () => {
        setIsGlobalLoading(true);
        situacaoCursoService.findList(predicate)
            .then((response) => {
                setSituacaoCursos(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findTipoCurso = () => {
        setIsGlobalLoading(true);
        tipoCursoService.findList(predicate)
            .then((response) => {
                setTipoCursos(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findUsuario = () => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        usuarioService.findList(predicate)
            .then(({ data }) => {
                setUsuarios(data);
            });
    }

    const findProduto = () => {
        setIsGlobalLoading(true);
        produtoEscolaService.findList(predicate)
            .then((response) => {
                setProdutoEscola(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const enviarArquivo = async () => {
        setIsGlobalLoading(true);

        const fetchTrilha = async () => {
            try {
                let updatedUploadCurso = {
                    ...curso, trilha: {
                        ...curso.trilha, id: idTrilha
                    }
                };

                if (sendFile) {
                    const { data } = await arquivoService.upload(sendFile);
                    updatedUploadCurso = {
                        ...updatedUploadCurso,
                        arquivo: {
                            id: data.id,
                            codigo: data.codigo,
                            url: data.url,
                            ativo: false,
                            relatorioAula: null
                        }
                    };
                } else {
                    updatedUploadCurso = {
                        ...updatedUploadCurso,
                        arquivo: null,
                    }
                }

                if (!idCurso) {
                    await cursoService.enviarArquivo(updatedUploadCurso);
                    history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos`);
                    notification({
                        description: `Curso cadastrado com sucesso, por gentileza atualizar a página.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                } else {
                    await cursoService.editarArquivoCustom(curso.id, updatedUploadCurso);
                    history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos`);
                    notification({
                        description: `Curso alterado com sucesso, por gentileza atualizar a página.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                }
            } catch (error) {
                notification({
                    description: `Desculpe, não foi possível editar o curso.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }
            setIsGlobalLoading(false);
        };

        fetchTrilha();
    };

    const submitValidationFields = () => {
        if (!curso?.codigoProduto) {
            document.getElementById('codigoProduto')?.focus();
            notification({
                description: `Informar o código do produto.`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }
        if (curso?.id && sendFile && !validaArquivo && validateFileExtension(sendFile?.name)) {
            document.getElementById('arquivoCapa')?.focus();
            notification({
                description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (!curso?.id && !sendFile && !validaArquivo && validateFileExtension(sendFile?.name)) {
            document.getElementById('capaCurso')?.focus();
            notification({
                description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (validaArquivo && validateFileExtension(curso?.capaCurso)) {
            document.getElementById('linkCapaCurso')?.focus();
            notification({
                description: `A extensão do link selecionado para a "capa" não é permitida. Por favor, selecione um link com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }
        enviarArquivo();
    }

    useEffect(() => {
        findModalidade();
        findNegocio();
        findSituacaoCurso();
        findTipoCurso();
        findUsuario();
        findProduto();

        if (idCurso) {
            findCursos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const limparArquivo = () => {
        setSendFile(null);
        setCurso({ ...curso, arquivoCapaByte: null })
    }

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ justifyContent: "normal", width: "62%" }}>
                <div className="space1" />
                <div className="user-row">
                    <div className="card-form" >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 id="taxas-h3" style={{ marginBottom: 10 }}>Insira informações para cadastro da curso</h3>
                            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
                        </div>
                        <div className="input-box">
                            <label htmlFor="nome">Nome</label>
                            <Input
                                id="nome"
                                defaultValue={curso?.nome}
                                value={curso?.nome}
                                onChange={(e) => setCurso({ ...curso, nome: e.target.value })} />
                        </div>
                        {/* <div className="input-box">
                            <label htmlFor="codigoProduto">Código Produto</label>
                            <Input
                                id="codigoProduto"
                                defaultValue={curso?.codigoProduto}
                                value={curso?.codigoProduto}
                                onChange={(e) => setCurso({ ...curso, codigoProduto: e.target.value })} />
                        </div> */}

                        <div className="input-box">
                            <label htmlFor="situacaoCurso">Código Produto</label>
                            <Select
                                style={{ width: "100%" }}
                                id="situacaoCurso"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={curso?.codigoProduto}
                                value={curso?.codigoProduto}
                                onChange={(e) => setCurso({ ...curso, codigoProduto: e })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                <Select.Option value={TipoProduto.TIPO_PRODUTO_CODIGO}>Curso Padrão</Select.Option>
                                {produtoEscola && produtoEscola.map(produto =>
                                    <Select.Option key={produto.id} value={produto.codigo} text={produto.descricao}>{produto.descricao}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className="input-box">
                            <label htmlFor="descricao">Descrição</label>
                            <TextArea
                                id="descricao"
                                maxLength={200}
                                defaultValue={curso?.descricao}
                                value={curso?.descricao}
                                onChange={(e) => setCurso({ ...curso, descricao: e.target.value })} />
                            <ContadorCaracteres
                                contador={curso?.descricao?.length}
                                limite={200} />
                        </div>
                        <div className="input-box">
                            <label htmlFor="modalidade">Modalidade</label>
                            <Select
                                style={{ width: "100%" }}
                                id="modalidade"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={curso?.modalidade?.id}
                                value={curso?.modalidade?.id}
                                onChange={(e) => setCurso({ ...curso, modalidade: { ...curso.modalidade, id: e } })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {modalidades.map(modalidade =>
                                    <Select.Option key={modalidade.id} value={modalidade.id} text={modalidade.descricao}>{modalidade.descricao}</Select.Option>
                                )}
                            </Select>
                        </div>
                        <div className="input-box">
                            <label htmlFor="negocio">Negócio</label>
                            <Select
                                style={{ width: "100%" }}
                                id="negocio"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={curso?.negocio?.id}
                                value={curso?.negocio?.id}
                                onChange={(e) => setCurso({ ...curso, negocio: { ...curso.negocio, id: e } })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {negocios.map(negocio =>
                                    <Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>{negocio.descricao}</Select.Option>
                                )}
                            </Select>
                        </div>
                        {/* <div className="input-box">
                            <label htmlFor="situacaoCurso">Situação do curso</label>
                            <Select
                                style={{ width: "100%" }}
                                id="situacaoCurso"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={curso?.situacaoCurso?.id}
                                value={curso?.situacaoCurso?.id}
                                onChange={(e) => setCurso({ ...curso, situacaoCurso: { ...curso.situacaoCurso, id: e } })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {situacaoCursos.map(situacaoCurso =>
                                    <Select.Option key={situacaoCurso.id} value={situacaoCurso.id} text={situacaoCurso.descricao}>{situacaoCurso.descricao}</Select.Option>
                                )}
                            </Select>
                        </div> */}
                        <div className="input-box">
                            <label htmlFor="situacaoCurso">Tipo curso</label>
                            <Select
                                style={{ width: "100%" }}
                                id="situacaoCurso"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={curso?.tipoCurso?.id}
                                value={curso?.tipoCurso?.id}
                                onChange={(e) => setCurso({ ...curso, tipoCurso: { ...curso.tipoCurso, id: e } })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {tipoCursos.map(tipoCurso =>
                                    <Select.Option key={tipoCurso.id} value={tipoCurso.id} text={tipoCurso.descricao}>{tipoCurso.descricao}</Select.Option>
                                )}
                            </Select>
                        </div>
                        <div className="input-box">
                            <label htmlFor="situacaoCurso">Ministrado por</label>
                            <Select
                                style={{ width: "100%" }}
                                id="situacaoCurso"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={curso?.usuarioInstrutor?.id}
                                value={curso?.usuarioInstrutor?.id}
                                onChange={(e) => setCurso({ ...curso, usuarioInstrutor: { ...curso?.usuarioInstrutor, id: e } })}
                                filterOption={(input, option) =>
                                    option && option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {usuarios && usuarios.map(instrutor =>
                                    <Select.Option key={instrutor?.id} value={instrutor?.id} text={instrutor?.pessoa?.nome}>{instrutor?.pessoa?.nome}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className="input-box">
                            <label htmlFor="happyCoins">Happy Coins</label>
                            <InputNumber
                                style={{ width: "100%" }}
                                id="happyCoins"
                                type="number"
                                min={0}
                                defaultValue={curso?.happyCoins}
                                value={curso?.happyCoins}
                                onChange={(e) => setCurso({ ...curso, happyCoins: e })} />
                        </div>
                        <div style={{ display: "flex" }} >
                            <div className="input-box" style={{ marginRight: 10 }}>
                                <label htmlFor="dataInicioCurso">Data inicial do curso</label>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    id="dataInicioCurso"
                                    allowClear={true}
                                    placeholder={'Nova data'}
                                    size={"large"}
                                    format={'DD/MM/YYYY'}
                                    defaultValue={curso?.dataInicioCurso ? moment(curso?.dataInicioCurso) : null}
                                    value={curso?.dataInicioCurso ? moment(curso?.dataInicioCurso) : null}
                                    onChange={(e) => setCurso({ ...curso, dataInicioCurso: e.toDate() })} />
                            </div>
                            <div className="input-box">
                                <label htmlFor="dataFinalCurso">Data final do curso</label>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    id="dataFinalCurso"
                                    allowClear={true}
                                    placeholder={'Nova data'}
                                    size={"large"}
                                    format={'DD/MM/YYYY'}
                                    defaultValue={curso?.dataFinalCurso ? moment(curso?.dataFinalCurso) : null}
                                    value={curso?.dataFinalCurso ? moment(curso?.dataFinalCurso) : null}
                                    onChange={(e) => setCurso({ ...curso, dataFinalCurso: e.toDate() })} />
                            </div>
                        </div>

                        <div className="input-box">
                            <label htmlFor="checkedIcone">Tipo do arquivo</label>
                            <Radio.Group
                                defaultValue={validaArquivo}
                                value={validaArquivo}
                                onChange={(e) => setValidaArquivo(e.target.value)}
                            >
                                <Space direction="vertical">
                                    <Radio value={false}>Arquivo</Radio>
                                    <Radio value={true} onClick={limparArquivo}>Link</Radio>
                                </Space>
                            </Radio.Group>
                        </div>

                        {!validaArquivo ?
                            <div className="input-box">
                                <label htmlFor="capaCurso">Upload arquivo de imagem</label>
                                <Input
                                    id="capaCurso"
                                    type="file"
                                    onChange={(e) => {
                                        setSendFile(e.target.files?.[0] || null)
                                        setCurso({ ...curso, capaCurso: null })
                                    }} />
                            </div>
                            :
                            <div className="input-box">
                                <label htmlFor="linkCapaCurso">Informar link da imagem</label>
                                <Input
                                    id="linkCapaCurso"
                                    defaultValue={curso?.capaCurso}
                                    value={curso?.capaCurso}
                                    onChange={(e) => setCurso({ ...curso, capaCurso: e.target.value.trim() })} />
                            </div>
                        }
                        <PresentImage
                            file={sendFile}
                            link={curso?.capaCurso ? curso?.capaCurso : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + curso?.arquivo?.url}
                            size={"100%"}
                        />

                        <div className="botoes" style={{ marginTop: 10 }}>
                            <Link to={`/academy/gestao-trilha/trilha/${idTrilha}/cursos`}>
                                <Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>Voltar</Button>
                            </Link>
                            <Button
                                style={{ width: 345 }}
                                type="primary"
                                id="proximo"
                                className="button-primary"
                                onClick={submitValidationFields}>Salvar</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a operação?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2={`/academy/gestao-trilha/trilha/${idTrilha}/cursos`}
            />
        </div >
    )
}

export default CursoCreate;
