import { useState } from "react";
import { IAcaArquivoCleanDTO } from "../../../../../models/happy-code-api.model";

export default function  useClickTable(){
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<IAcaArquivoCleanDTO[]>([]);

    const handleRowClick = (record) => {
      if (record.arquivos.length > 0) {
        setSelectedFiles(record.arquivos);
        setModalOpen(true);
      }
    };
    const closeModal = () => {
      setModalOpen(false);
    };
    return{
       modalOpen,
       selectedFiles,
       handleRowClick,
       closeModal
    }
}