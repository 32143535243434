/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { INegocioFullDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsNegocioContext {
	negocio: INegocioFullDTO;
	revisao: boolean;
	setNegocio: React.Dispatch<React.SetStateAction<INegocioFullDTO>>;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	removeNegocio: React.DispatchWithoutAction;
}

//Valor default do contexto
const DEFAULT_VALUE: PropsNegocioContext = {
	negocio: {
		id: null,
		codigo: '',
		descricao: '',
		cor: '',
		corSecundaria: '',
		nomeCurto: '',
		horaSemanal: 0,
		valorHora: 0,
		valorHoraRematricula: 0,
		flTaxaMatricula: false,
		valorSplitTaxa: null,
		flTaxaRematricula: false,
		valorSplitTaxaRematricula: 0,
		valorOferta: 0,
		valorOfertaRematricula: 0,
		valorMinimo: 0,
		valorMinimoRematricula: 0,
		prorrogacaoDiasMatricula: 0,
		prorrogacaoDiasRematricula: 0,
		numeroParcelasMaximo: 0,
		numeroParcelasMaximoRematricula: 0,
		valorSugerido: 0,
		valorSugeridoCapital: 0,
		ativo: true,
		cursos: [],
		negocioUnidades: [],
		tipoCurriculo: null,
		transferencia: false,
		icone: null,
		imgUrl: null,
		imgCodigo: null,
	},
	revisao: false,
	setNegocio: () => {}, //função de inicialização
	setRevisao: () => {}, //função de inicialização
	removeNegocio: () => {}, //função de inicialização
};

//criando nosso contexto UserContext
const NegocioContext = createContext<PropsNegocioContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/negocio';
const nameStorageRevisao = '@HappyCode/negocio/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const NegocioContextProvider: React.FC = ({ children }: any) => {
	const [negocio, setNegocioGlobal] = useState<INegocioFullDTO>(DEFAULT_VALUE.negocio);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

	const setNegocio = (negocio) => {
		setNegocioGlobal(negocio);
		setObject(nameStorage, negocio);
	};

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const removeNegocio = () => {
		setRevisao(false);
		setNegocioGlobal(DEFAULT_VALUE.negocio);
		removeKey(nameStorage);
	};

	useEffect(() => {
		const negocio = getObject(nameStorage);
		if (negocio) {
			setNegocioGlobal(negocio);
		}
		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}
	}, []);

	return (
		<NegocioContext.Provider
			value={{
				negocio,
				revisao,
				setNegocio,
				setRevisao,
				removeNegocio,
			}}
		>
			{children}
		</NegocioContext.Provider>
	);
};

export const useNegocioContext = (): PropsNegocioContext => {
	const context = useContext(NegocioContext);
	if (!context) throw new Error('useNegocioContext must be used within a NegocioContextProvider');
	return context;
};
