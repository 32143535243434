import { useHistory } from 'react-router-dom';
import { classMiniatureIcons } from '../../constants/class-miniature-icons';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { IMatriculaDTO, ITurmaGradeCleanDTO } from '../../models/happy-code-api.model';
import { useTurmaService } from '../../services/turma.service';
import { OffersChipType } from '../../types/offer/offers-chip.type';
import useMiniatura from './use-miniatura.hook';

interface Props {
	cor: string;
	corSecundaria: string;
	variant: OffersChipType;
	turma: ITurmaGradeCleanDTO;
	telaTurma: boolean;
	horario: string;
	diaSemana: string;
	idMiniatura: number;
	urlIcone?: string;
	codigoIcone?: string;
	currentContractEnrollments: IMatriculaDTO[];
	otherEnrollmentsData: IMatriculaDTO[];
}

export const CardMiniaturaTurma = ({ variant, cor, corSecundaria, turma, telaTurma, idMiniatura, urlIcone, codigoIcone, currentContractEnrollments, otherEnrollmentsData }: Props) => {
	const { turmaSelectedClass, selectTurmaOnClick, disabled, turmaCinzaClass, isTurmaSelected } = useMiniatura({
		turma,
		variant,
		currentContractEnrollments,
		otherEnrollmentsData,
	});

	const { setIsGlobalLoading } = useGlobalContext();
	const { setTurma, editBtnIsVisible } = useTurmaContext();
	const turmaService = useTurmaService();
	const history = useHistory();

	const handleEditTurma = (turma) => {
		setIsGlobalLoading(true);
		turmaService
			.findFullById(turma)
			.then((response) => {
				setTurma(response.data);
				history.push('/turmas/create/step-2');
				editBtnIsVisible(true);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const getUrl = () => {        
        return `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${urlIcone}?name=${codigoIcone}`;
    }

	return (
		<>
			<button
				disabled={disabled}
				onClick={() => (!telaTurma ? selectTurmaOnClick() : handleEditTurma(turma))}
				style={{ backgroundColor: !isTurmaSelected ? cor : '#1A4DA1' }}
				className={`card__miniatura d-flex-col-start-star box-space-grid-${idMiniatura} ${turmaCinzaClass}`}
			>
				<div className="d-flex-row-start-center w-200 gap-3">
					<img src={urlIcone != null ? getUrl() : ''} alt="" />
					<p>{turma?.descricaoCurriculo + ' (' + turma?.faixaEtariaIni + ' - ' + turma?.faixaEtariaFim + ')'}</p>
				</div>
				<div className="d-flex-row-between-center box-info">
					<div style={{backgroundColor: corSecundaria != null ? corSecundaria : 'gray'}} className="box-sala">
						<p>{turma?.descricaoSala}</p>
					</div>
					<div style={{backgroundColor: corSecundaria != null ? corSecundaria : 'gray'}} className="box-vaga">
						<p>{turma?.maxAlunos}</p>
					</div>
				</div>
			</button>
		</>
	);
};
